import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import aes from "./utils/aes.js";
import util from "./utils/util.js";
Vue.config.productionTip = false;
import axios from "axios";
Vue.prototype.$http = axios;

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import Viewer from "v-viewer";
Vue.use(Viewer);
//要安装 viewerjs
import "viewerjs/dist/viewer.css";

import Moment from "moment";
Vue.prototype.$moment = Moment;

import vueEsign from "vue-esign";
Vue.use(vueEsign);

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import Print from "vue-print-nb";
Vue.use(Print);

import dataV from "@jiaminghi/data-view"; //首页大屏
Vue.use(dataV);
import "../public/css/global.less";
import "../public/css/theme/index.css";

import _lo from "lodash";
Vue.prototype._lo = _lo;

// 全局自定义指令
Vue.directive("focus", {
  inserted(el, binding) {
    if (el.tagName.toLocaleLowerCase() == "input") {
		el.focus()
	  } else {
		if (el.getElementsByTagName("input")) {
		  el.getElementsByTagName("input")[0].focus()
		}
	  }
	  el.focus()
  },
});

//获取用户token
let token = ""; //这个地方不能传null，否则后端会解析成字符串'null'
let getToken = (_) => {
  // if (!token) {
  if (localStorage.getItem("auth")) {
    //从session中取到token后，再解密
    token = aes.decrypt(localStorage.getItem("auth"));
  } else if (localStorage.getItem("token")) {
    //从session中取到token后，再解密
    token = localStorage.getItem("token");
  }
  // }
  return token;
};

axios.interceptors.request.use(
  (req) => {
    //生成 c s f r 校验
    let v =
      String.fromCharCode(90 + 9) +
      String.fromCharCode(100 + 15) +
      String.fromCharCode(100 + 7 * 2) +
      String.fromCharCode(51 * 2);
    let c = {};
    let d = new Date();
    c[v] = d.getTime() + "_" + parseInt(Math.random() * 100000);
    c[v] = aes.encrypt(c[v]);
    let hour = d.getHours(),
      min = d.getMinutes(),
      sec = d.getSeconds();
    hour < 10 ? (hour = "0" + hour) : true;
    min < 10 ? (min = "0" + min) : true;
    sec < 10 ? (sec = "0" + sec) : true;
    c["_" + v] = aes.encrypt(hour + ":" + min + ":" + sec);
    req.headers[v] = c[v] + "_" + c["_" + v];
    req.headers["Authorization"] = getToken();
    if (req.url.indexOf("http") >= 0) {
    } else {
      if (window.location.href.indexOf("localhost") > 0) {
        req.url = "http://127.0.0.1:10100" + req.url;
        // req.url = "http://frp.aiwx.org.cn:8555" + req.url
        //req.url = "//api-sch-saas.aiwx.org.cn" + req.url
      } else if (window.location.href.indexOf("192.1681") > 0) {
        // req.url = "http://192.168.0.102:10020" + req.url
        req.url = "http://192.168.99.110:10020" + req.url;
      } else if (window.location.href.indexOf("172.31") > 0) {
        req.url = "http://172.31.63.198:10100" + req.url;
      } else {
        req.url = "//ngzzc-api.aiwx.org.cn" + req.url;
      }
    }
    if (req.data && !req.data.noloading) {
      $("#loading").show();
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    $("#loading").hide();
    if (
      response.data.code == 401 ||
      response.data.name == "TokenExpiredError"
    ) {
      localStorage.clear();
      // window.location.href = window.auth.server + "?pmid=" + window.pmid + "&" + window.auth.redirect + "=" +
      // 	encodeURIComponent(window.location.href)
      window.location.href = "/login";
    } else {
      if (response.data.code && response.data.code != 200) {
        $("#errdg").html(response.data.msg).show();
        setTimeout(() => {
          $("#errdg").hide();
        }, 3000);
        return Promise.reject(null);
      } else {
        return response;
      }
    }
  },
  (error) => {
    console.log(error);
    if (error && error.response && error.response.status == 401) {
      localStorage.clear();
      window.location.href = "/login";
      // window.location.href = window.auth.server + "?pmid=" + window.pmid + "&" + window.auth.redirect + "=" +
      // 	encodeURIComponent(window.location.href)
    } else {
      return Promise.reject(error);
    }
  }
);

Vue.prototype.uploadTemp = (fileObj) => {
  console.log("初始化");
  var myDate = new Date();
  var ossPath = "files/" + myDate.getFullYear() + "-" + (myDate.getMonth() + 1);
  // 获取文件后缀
  var pathArr = fileObj.file.name.split(".");
  //  随机生成文件名称
  var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000);
  var fileName = fileRandName + "." + pathArr[pathArr.length - 1];
  // 要提交的key
  var fileKey = ossPath + "/" + fileName;
  let formData = {
    name: fileObj.file.name,
    key: fileKey,
    policy:
      "eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==",
    OSSAccessKeyId: "LTAI0Dd9MsOqnVoj",
    signature: "Xst4hA4qiPYO5HXG/8CcQgDTm9U=",
    success_action_status: "200",
  };
  var host = "https://mmtemp.oss-cn-hangzhou.aliyuncs.com";
  var outurl = host;
  var form = new FormData();
  // 其他参数
  for (let k in formData) {
    form.append(k, formData[k]);
  }
  form.append("file", fileObj.file);
  // XMLHttpRequest 对象
  var xhr = new XMLHttpRequest();
  xhr.open("post", host, true);
  xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
  xhr.onload = (res) => {
    if (fileObj.onSuccess) {
      fileName = fileName.toLocaleLowerCase();
      //计算图片高度
      if (
        fileName.indexOf(".jpg") > 0 ||
        fileName.indexOf(".jpeg") > 0 ||
        fileName.indexOf(".png") > 0 ||
        fileName.indexOf(".gif") > 0 ||
        fileName.indexOf(".bmp") > 0
      ) {
        var reader = new FileReader();
        reader.readAsDataURL(fileObj.file);
        reader.onload = function (theFile) {
          var image = new Image();
          image.src = theFile.target.result;
          image.onload = function () {
            fileObj.onSuccess({
              src:
                outurl + "/" + fileKey + "?s=" + this.width + "_" + this.height,
              fileName: fileObj.file.name,
            });
          };
        };
      } else {
        fileObj.onSuccess({
          src: outurl + "/" + fileKey,
          fileName: fileObj.file.name,
        });
      }
    }
  };
  xhr.onerror = (res) => {
    if (fileObj.onError) {
      fileObj.onError(res);
    }
  };
  xhr.send(form);
};

Vue.prototype.uploadOss = (fileObj) => {
  var myDate = new Date();
  var ossPath =
    "files/" +
    myDate.getFullYear() +
    "-" +
    (myDate.getMonth() + 1) +
    "-" +
    myDate.getDate();
  // 获取文件后缀
  var pathArr = fileObj.file.name.split(".");
  //  随机生成文件名称
  var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000);
  var fileName = fileRandName + "." + pathArr[pathArr.length - 1];
  // 要提交的key
  var fileKey = ossPath + "/" + fileName;
  let formData = {
    name: fileObj.file.name,
    key: fileKey,
    policy:
      "eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==",
    OSSAccessKeyId: "LTAI4G55gyD65iSg836oWSK4",
    signature: "7a7dfIH8bpoHXTeZxd+3xGS/g54=",
    success_action_status: "200",
    app: "zichan",
  };
  var host = "https://oss.aiwx.org.cn/upload";
  var outurl = host;
  var form = new FormData();
  // 其他参数
  for (let k in formData) {
    form.append(k, formData[k]);
  }
  form.append("file", fileObj.file);
  // XMLHttpRequest 对象
  var xhr = new XMLHttpRequest();
  xhr.open("post", host, true);
  xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
  xhr.onload = (res) => {
    if (fileObj.onSuccess) {
      console.log(res);
      let resObj = res.currentTarget.responseText;
      try {
        resObj = JSON.parse(resObj);
      } catch (e) {
        resObj = {};
      }
      fileName = fileName.toLocaleLowerCase();
      //计算图片高度
      if (
        fileName.indexOf(".jpg") > 0 ||
        fileName.indexOf(".jpeg") > 0 ||
        fileName.indexOf(".png") > 0 ||
        fileName.indexOf(".gif") > 0 ||
        fileName.indexOf(".bmp") > 0
      ) {
        var reader = new FileReader();
        reader.readAsDataURL(fileObj.file);
        reader.onload = function (theFile) {
          var image = new Image();
          image.src = theFile.target.result;
          image.onload = function () {
            if (resObj.src) {
              resObj.src += "?s=" + this.width + "_" + this.height;
            }
            fileObj.onSuccess(resObj);
          };
        };
      } else {
        fileObj.onSuccess(resObj);
      }
    }
  };
  xhr.onerror = (res) => {
    if (fileObj.onError) {
      fileObj.onError(res);
    }
  };
  xhr.send(form);
};

Vue.prototype.$$parent = (varname) => {
  console.log(this);
};

function $$parent() {
  let parent = arguments[0];
  let func = arguments[1];
  let para = [];
  for (let i = 2; i < arguments.length; i++) {
    para.push(arguments[i]);
  }
  return new Promise(
    (resolve) => {
      let checkParent = (idx) => {
        if (parent.$parent && idx < 20) {
          parent = parent.$parent;
          if (parent[func] !== undefined) {
            if (typeof parent[func] == "function") {
              parent[func](...para);
              resolve(true);
            } else {
              if (arguments.length >= 3) {
                //判定是赋值
                parent[func] = arguments[2];
                resolve(parent[func]);
              } else {
                //判定是读值
                resolve(parent[func]);
              }
            }
          } else {
            checkParent(idx + 1);
          }
        } else {
          console.log("未找到：" + func);
          resolve(undefined);
        }
      };
      checkParent(0);
    },
    (reject) => {}
  );
}
Vue.prototype.$$parent = $$parent;

for (let k in util) {
  Vue.prototype[k] = util[k];
}

var globalCurrentDate = "";
let _getGlobalCurDate = () => {
  var myDate = new Date();
  let year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
  let month = (myDate.getMonth() + 1).toString().padStart(2, "0"); //获取当前月份(0-11,0代表1月)
  let d = myDate.getDate().toString().padStart(2, "0");
  let h = myDate.getHours().toString().padStart(2, "0");
  let m = myDate.getMinutes().toString().padStart(2, "0");
  globalCurrentDate = `${year}-${month}-${d}`;
  return globalCurrentDate;
};
_getGlobalCurDate();
Vue.prototype.globalCurrentDate = globalCurrentDate;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="loginbg flexCenter">

    <div class="login_box flexStretch">

      <div class="login_box_left">
        <div class="appname">
          {{ config.schoolname }}
        </div>
        <img src="/img/login/login_form_bg.png" alt="">
      </div>

      <div class="login_box_right flexCloumnSingleCenter">
        <div class="appname">
          {{ config.appname }}
        </div>

        <div class="tabs flexCenter">
          <div class="tab_left" @click="logintype = 0" :style="{ color: logintype == 0 ? '#3D79EC' : 'inherit' }">
            扫码登录
            <div class="tab_line" :style="{ backgroundColor: logintype == 0 ? '#3D79EC' : 'inherit' }">

            </div>
          </div>
          <div class="tab_left" @click="logintype = 1" style="margin-left:40px;"
            :style="{ color: logintype == 1 ? '#3D79EC' : 'inherit' }">
            帐号登录
            <div class="tab_line" :style="{ backgroundColor: logintype == 1 ? '#3D79EC' : 'inherit' }">

            </div>
          </div>
        </div>

        <div class="flex_1 flexCloumnSingleCenter" style="margin-top: 20px;width: 100%;justify-content: space-between;"
          v-show="logintype == 0">
          <div class="logintype0"
            :style="qrcodeUrl ? { backgroundImage: 'url(' + qrcodeUrl + ')' } : { backgroundImage: 'url(../../images/loading.gif)' }">
            <!-- <img v-if="qrcodeUrl" :src="config.schoollogo" alt="" style="width: 46%;margin-top:27%; "> -->
          </div>
          <!-- <div style="height: 20px;" /> -->

          <div class="login_box_desc flexBetween" style="margin-bottom:20px;">
            <div class="flexStart">
              <el-checkbox label="本月免登" v-model="miandeng30" @change="changeMiandeng(30)"></el-checkbox>
              <el-checkbox label="本周免登" v-model="miandeng7" @change="changeMiandeng(7)" class="ml10"></el-checkbox>
            </div>
            <div class="link_text pointer" @click="forgetPwd">忘记密码
            </div>
          </div>


        </div>

        <div class="flex_1 flexCloumnSingleCenter" style="margin-top: 20px;width: 100%;justify-content: space-between;"
          v-show="logintype == 1">
          <div class="logintype1">
            <el-input class="shape" placeholder="账号" @keyup.enter.native="login" v-model="username"
              prefix-icon="el-icon-user" style="background-color: F5F8FE;">
            </el-input>
            <div style="height: 10px;" />
            <el-input class="shape" type="password" placeholder="密码" @keyup.enter.native="login" v-model="password"
              prefix-icon="el-icon-lock">
            </el-input>
            <div style="height: 10px;" />

            <el-input class="shape" placeholder="验证码" @keyup.enter.native="login" v-model="yzm"
              prefix-icon="el-icon-circle-check">
              <div slot="suffix" style="height: 40px;">
                <div class="flexCenter" @click="getYZM"
                  style="border: 0; width: 80px; height: 40px; transform: scale(1.2,1.2) translateY(0); transform-origin: center center;"
                  v-html="yzmUrl">
                </div>

              </div>

            </el-input>

            <div style="height: 10px;" />
            <div class="shape btn flexCenter" style="text-align: center;" @click="login">登 录</div>
          </div>
          <!-- <div style="height: 20px;" /> -->

          <div class="login_box_desc flexBetween" style="margin-bottom:20px;">
            <div class="flexStart">
              <el-checkbox label="本月免登" v-model="miandeng30" @change="changeMiandeng(30)"></el-checkbox>
              <el-checkbox label="本周免登" v-model="miandeng7" @change="changeMiandeng(7)" class="ml10"></el-checkbox>
            </div>
            <div class="link_text pointer" @click="forgetPwd">忘记密码
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="copy_right flexCenter">
      Copyright © 版权所有：{{ config.schoolname }} - Powered By {{ config.poweredBy }}
    </div>

  </div>
</template>

<script>
import md5 from 'js-md5';
export default {
  data() {
    return {
      username: "",
      password: "",
      session_id: "",
      qrcodeSession: "",
      yzmUrl: "",
      yzm: "",
      redirectUrl: "",
      pmid: 0,
      logintype: 0,
      qrcodeUrl: "",
      miandeng30: false,
      miandeng7: false,
      config: {},
    }
  },
  mounted() {


    // if(!this.redirectUrl){
    //   this.redirectUrl = this.$request("undefined")
    // }

    this.pmid = 201
    this.getConfig()
    this.getQrcode()
    //      let data = {app:"ngz",token:"",timestamp: parseInt(new Date().getTime()/1000),year:2022,appid:1}
    //     data.timestamp=data.timestamp+"_"+ parseInt(Math.random()*100000)
    //     data.token= md5("cad386cf409f3f25_"+data.timestamp)
    // console.log(data)
    //this.$http.post("http://zsapi.mmteck.cn/api/ngz_students_detail",data)


    setTimeout(() => {
      console.log(this.miandeng)
    }, 5000)
  },
  methods: {
    changeMiandeng(day) {

      if (day == 30) {
        this.miandeng7 = false;
        //this.miandeng30=!this.miandeng30
      } else if (day == 7) {
        this.miandeng30 = false;
        //this.miandeng7=!this.miandeng7
      }
    },
    getConfig() {
      let config = {}
      this.$http.post("/api/sys_dictionary_list", {
        pmid: this.pmid
      }).then(res => {
        if (res.data && res.data.length > 0) {
          res.data.map(a => {
            if (a.remark.indexOf('app基础配置') > -1 && a.key_values) {
              let json_Arr = JSON.parse(a.key_values)
              for (let item of json_Arr) {
                config[item.field] = item.value
                if (item.field == "favico_name") {
                  document.title = item.value
                } else if (item.field == "favico") {
                  var link = document.createElement('link');
                  link.type = 'image/x-icon';
                  link.rel = 'shortcut icon';
                  link.href = item.value.replace("https:", '').replace("http:", '');
                  document.getElementsByTagName('head')[0].appendChild(link);
                }
              }
              this.config = config
            }
          })
        }
        this.getYZM()
      })
    },

    getQrcode() {
      this.$http.post("/api/login_qrcode", {
        pmid: this.pmid
      }).then(res => {

        this.qrcodeSession = res.data.session;

        this.qrcodeUrl = res.data.url;
        if (!this.qrcodeChecking) {
          this.qrcodeChecking = true

          this.checkQrcode();
        }
      })



    },
    checkQrcode() {

      this.qrcodeTimer = setInterval(() => {

        this.$http.post("/api/check_qrcode", {
          session_id: this.qrcodeSession,
          noloading: 1,
          pmid: this.pmid
        }).then(res => {
          if (res.data && res.data.auth) {
            window.localStorage.setItem("auth", res.data.auth)
            clearInterval(this.qrcodeTimer)
            this.getRoleMenu()

          } else {
            if (res.data.code != 200) {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: '600'
              });

              clearInterval(this.qrcodeTimer)
            }

          }

        })
      }, 1000)
    },
    getYZM() {
      this.$http.post("/api/get_login_randomcode").then(res => {
        this.session_id = res.data.data.session_id;
        this.yzmUrl = res.data.data.svg;
      })
    },
    login() {
      let day = 1;
      if (this.miandeng30) {
        day = 30;
      } else if (this.miandeng7) {
        day = 7;
      }
      this.$http.post("/api/admin_login", {
        username: this.username.trim(),
        password: this.password.trim(),
        yzm: this.yzm.trim(),
        pmid: this.pmid,
        session_id: this.session_id,
        md: day
      }).then(res => {
        //res[0] token //[1] info [2] menus
        if (res.data && res.data.auth) {

          localStorage.setItem("md", day + "_" + (new Date().getTime()))
          window.localStorage.setItem("auth", res.data.auth)
          clearInterval(this.qrcodeTimer)
          this.getRoleMenu()


        } else {
          this.$message({
            message: res.data.msg,
            type: 'error',
            offset: '600'
          });

        }


      }, err => {

      })
    },
    getRoleMenu() {

      this.$http.post("/api/zichan_teacher_menu").then(res => {
        let firstMenu = "";
        for (let p of res.data) {
          if (p.path != "/home") {
            if (p.children && p.children.length > 0) {
              firstMenu = p.children[0].path
              break
            } else if (p.path) {
              firstMenu = p.path
              break
            }
          }
        }
        localStorage.setItem("firstMenu", firstMenu)



        for (let item of res.data) {
          if (item.children && item.children.length > 0) {
            this.$router.push(item.children[0].path)
            break;
          } else if (item.path) {
            this.$router.push(item.path)
            break;
          }
        }

      })
    },
    forgetPwd() {
      this.$message.success("请联系系统管理员")
    }
  }
}
</script>
<style lang="less">
.logintype1 {

  .el-input__inner:focus,
  .el-input__inner:hover {
    border: 0;
  }

  .el-input__inner {
    background-color: transparent !important;
    border: 0;

    input {
      background-color: transparent !important;
    }
  }



}
</style>
<style scoped lang="less">
.loginbg {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: url('../../public/img/login/bg.png');

  .login_box {
    width: 48%;
    height: 48%;

    .appname {
      position: relative;
      z-index: 999;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      /*color: #5790F0;
      text-shadow: 2px 2px 5px #5790F081;*/
      margin: 30px 0 10px 0;
      background: -webkit-linear-gradient(to bottom, #49BFFF, #003CA2);
      background: linear-gradient(to bottom, #49BFFF, #003CA2);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
    }

    .login_box_left {
      position: relative;
      width: 50%;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

      }
    }

    .login_box_right {
      width: 50%;
      background: #fff;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;

      .tabs {
        width: 80%;
        text-align: center;
        cursor: pointer;

        .tab_left {
          word-break: keep-all;
          font-weight: 600;

          .tab_line {
            background-color: #3D79EC;
            width: 50px;
            margin: 0 auto;
            height: 4px;
            border-radius: 2px;
            margin-top: 5px;
          }
        }
      }


      .logintype0 {
        width: 50%;
        height: 0;
        padding-bottom: 50%;
        margin: 0 auto;
        text-align: center;
        background-position: center;
        background-size: cover;
      }

      .logintype1 {
        display: block;
        width: 85%;
        height: auto;
        overflow: hidden;
        margin: 0 auto;

        .shape {
          width: calc(100% - 2px);
          border: 1px solid #DCDFE6;
          background-color: #F5F8FE;
          height: 40px;
          border-radius: 4px;
          overflow: hidden;
        }

        .btn {
          background-color: #337FF1;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .login_box_desc{
        width: 85%;
        .link_text{
  word-break: keep-all;
  font-size: 14px;
color: #1289ff;
        }
        .link_text:hover{
color: #1289ff;
        }
      }
    }
  }

  .copy_right {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 9%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #fff;
  }

  /deep/ .el-input__inner {
    height: 100% !important;
    padding-right: 0 !important;
  }
    .el-checkbox{
      margin-right: 0!important;
    }
}
</style>

<template>
	<div class="menuWrapper">
		<div class="menuWrapper">

			<div class="header flexBetween">
				<div :class="['leftlogo flexCenter', $store.state.leftmenu ? '' : 'hidelogo']">
					<div class="thumbnail flexCenter">
						<img v-if="$store.state.config.leftlogo" :src="$store.state.config.leftlogo" alt="">
					</div>


				</div>
				<div v-if="$store.state.config.pc_left_appname"
					:class="['moudelname', $store.state.leftmenu ? '' : 'hidelogo']">{{
	$store.state.config.pc_left_appname
					}}</div>
				<div class="menubar flex_1">
					<el-tabs v-model="activeTab" ref="tabs">
						<el-tab-pane v-for="(item, idx) in Menus" :key="idx" :name="item.id + ''">
							<div slot="label" style="height:100%;">
								<div :class="[
					'bmitem bgBlue flexCenter',
					nowbMenu == item.id ? 'bmckd' : '',
				]" @click="goBMenu(item)">
									<div class="flexStart" style="height:100%;">
										<i v-if="!item.icon0 && !item.icon1" :class="item.icon"
											style="color: inherit;"></i>
										<img v-if="item.icon1 && nowsMenu == item.id" :src="item.icon1" alt=""
											style="width: 15px;height: 15px;">
										<img v-if="item.icon0 && nowsMenu != item.id" :src="item.icon0" alt=""
											style="width: 15px;height: 15px;">
										<span style="margin-left: 4px;">{{ item.title }}</span>
									</div>
									<div class="ckdline"></div>
								</div>

							</div>
						</el-tab-pane>

					</el-tabs>

				</div>



				<div class="headright flexCenter">
					<!-- 上传的小程序码海报 -->

					<div v-if="miniqrcode_original">
						<el-popover placement="bottom" width="300" trigger="hover"
							popper-class="popper-class-cus flexCloumnSingleCenter">
							<div class="flexCenter" style="width: 260px;">
								<!-- <img style="width:160px;height:160px;" src="../../../public/img/miniQR.png" alt=""> -->
								<div style="position: relative;width: 100%; text-align: center; margin: 0 auto;">
									<el-image :src="miniqrcode_original" alt=""
										style="position: unset;width: 100%;"></el-image>
								</div>

							</div>
							<el-button @click="downloadqrcode(miniqrcode_original)" type="primary"
								icon="el-icon-download" size="small">下载图片到本地</el-button>

							<div slot="reference" class="miniQRBox">
								<img src="../../../public/img/miniQR.png" alt="">
							</div>
						</el-popover>
					</div>

					<el-dropdown @command="handleCommand" trigger="click">
						<div class="flexEnd" style="height: 50px;">


							<div class="avatarImg">
								<img :src="avatar || require('../../../public/img/temp.png')" alt="">
							</div>
							<div class="nickname flexStart">
								<div style="margin-right: 6px;">
									{{ userName }}
								</div>
								<i class="el-icon-caret-bottom"></i>

							</div>
						</div>

						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="updPwd" divided>修改密码</el-dropdown-item>
							<el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>

			</div>

			<div class="menuline"></div>

			<div :class="['leftmenu', $store.state.leftmenu ? '' : 'hidemenu']">
				<div class="flexCloumn" style="width: 100%; margin: 0 auto; height: 100%;">
					<div style="height: 60px;"></div>

					<div class="smenubox" style=" border-radius: 6px;">
						<!-- <div class="smenubox" style=" border-radius: 6px;overflow-x:hidden;overflow-y:auto;"> -->

						<el-menu :default-active="nowsMenu.toString()" text-color="#879DF5" active-text-color="#3D62F6">
							<!-- 引入组件 -->
							<menuTree :menuData="Smenus"></menuTree>
						</el-menu>
					</div>
				</div>
			</div>
		</div>

		<el-dialog title="修改密码" class="cus_dialog" :visible.sync="isShowModal" :close-on-click-modal="false"
			:destroy-on-close="true" width="500px">
			<el-form :model="pwdForm">
				<el-form-item>
					<el-input placeholder="请输入原密码" v-model="pwdForm.oldPwd" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">原密码</span></template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">新密码</span></template>
					</el-input>
				</el-form-item>

				<el-form-item>
					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">确认密码</span></template>
					</el-input>
				</el-form-item>

				<div style="height: 20px;text-align: center;padding-top: 30px;padding-bottom: 20px;">
					<el-button type="primary" style="width: 100%" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import menuTree from "./components/MenuTree";

export default {
	components: {
		menuTree
	},
	data() {
		return {

			avatar: "",
			Menus: [],
			Smenus: [],
			nowbMenu: 0,
			nowsMenu: "",
			nowsMenu2: "",
			userName: "",
			isShowModal: false,
			year: "",
			yearList: [],
			isShowYearModal: false,
			ltmenuHide: true,
			pwdForm: {
				oldPwd: "",
				newPwd1: "",
				newPwd2: "",
			},
			activeIndex: '',
			miniqrcodeurl: '',
			applogo: "",
			activeTab: '',
			miniqrcode_original: '',

		};
	},
	mounted() {
		let _this = this
		this.init()
		// this.getminiqrcode();
		this.getapplogo();

	},
	watch: {
		Smenus: function (n, o) {
			console.log(111, n)
			this.$parent.leftMenus = n;
		},
	},
	methods: {
		init() {
			let _this = this
			_this.getSGList().then(sg => {
				_this.getRKList().then(rk => {
					_this.getSLList().then(sl => {
						_this.getCKList().then(ck => {
							_this.getRoleMenu(sg, rk, sl, ck).then(_ => {

								if (window.location.href.indexOf('showIframe') > -1) {
									let nowbMenu = decodeURIComponent(_this.$GetQueryString('nowbMenu'))
									let nowsMenu = decodeURIComponent(_this.$GetQueryString('nowsMenu'))
									_this.nowbMenu = nowbMenu
									_this.nowsMenu = nowsMenu

									for (let item of _this.Menus) {
										if (!nowsMenu && item.id == this.nowbMenu) {

											_this.nowsMenu = 0
											this.$store.dispatch("showSmenu", 0)
											_this.$store.dispatch("smenuCount", 0)
										} else if (nowsMenu) {

											for (let sitem of item.children) {
												if (sitem.id == _this.nowsMenu) {
													_this.Smenus = item.children;
													_this.$store.dispatch("showSmenu", 1)
													_this.$store.dispatch("smenuCount", this.Smenus.length)
													break
												}
											}
										}
									}
								}
							});
						})
					})
				})
			})
		},
		getapplogo() {
			setTimeout(() => {
				console.log(51515151,this.$store.state.config)
				this.applogo = this.$store.state.config.schoollogo
				this.miniqrcode_original = this.$store.state.config.miniqrcode
			}, 200)
		},
		// getminiqrcode() {
		// 	this.$http.post("/api/login_qrcode").then(res => {
		// 		this.miniqrcodeurl = res.data.url
		// 	})
		// },
		downloadqrcode(url) {
			window.open(url)
		},
		isRightArrow() {
			this.$nextTick(() => {
				// 找出下面对应的nav实例
				function findTabNavVue(vueStance) {
					const childrens = vueStance.$children
					const len = childrens.length
					for (let i = 0; i < len; i++) {
						const child = childrens[i]
						if (child.scrollNext) return child
					}
				}
				const navVue = findTabNavVue(this.$refs.tabs)
				if (navVue.$el.querySelector('.el-tabs__nav-prev')) {
					navVue.$el.querySelector('.el-tabs__nav-prev').style.visibility = 'hidden'
				}
				if (navVue.scrollable) {
					let nextNode
					let nextNode2
					navVue.$watch('navOffset', function (currentOffset) {
						var navSize = this.$refs.nav['offsetWidth'];
						var containerSize = this.$refs.navScroll['offsetWidth'];
						const isRight = navSize - currentOffset <= containerSize
						const isLeft = currentOffset == 0
						nextNode = nextNode ? nextNode : navVue.$el.querySelector('.el-tabs__nav-next')
						nextNode.style.visibility = isRight ? 'hidden' : ''

						nextNode2 = nextNode2 ? nextNode2 : navVue.$el.querySelector('.el-tabs__nav-prev')
						nextNode2.style.visibility = isLeft ? 'hidden' : ''
					})
				}
			})
		},
		getRoleMenu(sg, rk, sl, ck) {
			return new Promise(resolve => {
				this.$http.post("/api/zichan_teacher_menu").then(res => {
					this.Menus = JSON.parse(JSON.stringify(res.data))

					this.getTeacherInfo();
					for (let item of this.Menus) {
						if (item.path && this.$route.path.indexOf(item.path) >= 0) {
							this.nowsMenu = 0
							this.nowbMenu = item.id

							this.$store.dispatch("showSmenu", 0)
							this.$store.dispatch("smenuCount", 0)
						} else {
							// console.log(279, item.title, item.children)
							for (let sitem of item.children) {
								// console.log(17715, sitem.title, sitem.path)
								if (sitem.path && this.$route.path.indexOf(sitem.path) >= 0) {
									this.nowsMenu = sitem.id
									this.nowbMenu = item.id
									let children = JSON.parse(JSON.stringify(item.children))
									children && children.map(a => {
										if (a.path && a.path.indexOf('/sx_purchase') >= 0) {
											console.log(277, sg)
											a.dotmsg = sg
										}
										if (a.path && a.path.indexOf('/sx_ruku') >= 0) {
											console.log(277, rk)
											a.dotmsg = rk
										}
										if (a.path && a.path.indexOf('/sx_shenling') >= 0) {
											console.log(277, sl)
											a.dotmsg = sl
										}
										if (a.path && a.path.indexOf('/sx_chuku') >= 0) {
											console.log(277, ck)
											a.dotmsg = ck
										}
									})
									this.Smenus = children;
									this.$store.dispatch("showSmenu", 1)
									this.$store.dispatch("smenuCount", this.Smenus.length)
									this.$store.dispatch("setNowsMenu", this.nowsMenu)
									break
								}
								if (sitem.children && sitem.children.length > 0) {
									for (let jitem of sitem.children) {
										if (jitem.path && this.$route.path.indexOf(jitem.path) >= 0) {
											this.nowsMenu = sitem.id
											this.nowbMenu = item.id
											this.Smenus = item.children;
											this.$store.dispatch("showSmenu", 1)
											this.$store.dispatch("smenuCount", this.Smenus.length)
											this.$store.dispatch("setNowsMenu", this.nowsMenu)
											break
										}
									}
								}
							}
						}
					}
					resolve()

					// 获取所有tab
					let _this = this
					if (this.Menus.length != 0) {
						setTimeout(() => {
							this.isRightArrow()
						}, 500);
					}
				})
			})
		},
		goBMenu(item) {
			console.log(317, item)
			this.Smenus = item.children;
			this.nowbMenu = item.id;
			if (this.Smenus && this.Smenus.length > 0) {
				this.goPage(this.Smenus[0]);
				this.init()//更新未处理红点提醒
				this.nowsMenu = this.Smenus[0].id;
				this.$store.dispatch("showSmenu", 1)
				this.$store.dispatch("smenuCount", this.Smenus.length)
			} else if (item.path) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path);
				}
				this.$store.dispatch("showSmenu", 0)
				this.$store.dispatch("smenuCount", 0)

			}
		},
		goPage(item) {
			this.$store.dispatch("setShowIframe", false)
			if (item.path.indexOf('http') < 0) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path)
					this.nowsMenu = item.id
					this.$store.dispatch("setNowsMenu", this.nowsMenu)
				}
			} else {
				if (window.location.href != item.path) {
					this.nowsMenu = item.id
					let path = item.path
					if (window.pmid == process.env.VUE_APP_PMID) {
						path = item.path.replace(/^https?:\/\/[^/]+/, "")
					}
					this.$router.push({
						path: "/iframe",
						query: {
							iframeUrl: path,
							t: Date.now(),
							nowsMenu: item.id,
							nowbMenu: this.nowbMenu
						}
					})

					this.$store.dispatch("setShowIframe", true)
					this.$store.dispatch("setIframeUrl", path)
					this.$store.dispatch("setNowsMenu", this.nowsMenu)
				}

			}
			console.log(427438, this.$store.state.nowsMenu)

		},
		setYear() {
			localStorage.setItem("year", this.year);
			window.location.reload();
		},
		handleCommand(e) {

			if (e == "updPwd") {
				this.isShowModal = true;
			} else if (e == "logout") {
				this.$http.post("/api/quit_login").then(res => { })
				localStorage.clear();
				sessionStorage.clear();
				this.$router.push("/login");
			} else if (e == "changeYear") {
				this.isShowYearModal = true;
			}
		},
		savePwd() {
			if (!this.pwdForm.oldPwd) {
				this.$message.error("请输入原密码");
				return;
			}

			if (!this.pwdForm.newPwd1) {
				this.$message.error("请输入新密码");
				return;
			}
			if (!this.pwdForm.newPwd2) {
				this.$message.error("请再次输入新密码");
				return;
			}
			if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
				this.$message.error("两次密码不一致");
				return;
			}
			this.$http.post("/api/reset_myself_pwd", this.pwdForm).then((ok) => {
				if (ok.data.code) {
					if (ok.data.code == 500) {
						this.$message.error(ok.data.msg);
					} else {
						this.$message.error("保存失败");
					}
				} else {
					this.$message.success("修改成功");

					this.isShowModal = false;
					this.$router.push("/login");
				}
			});
		},
		getTeacherInfo() {

			this.$http.post("/api/get_teacher_info").then(res => {
				this.avatar = res.data.avatar
				this.userName = res.data.username
				this.$store.dispatch("setTeacher", res.data)


			})
		},

		getSGList() {
			let _this = this
			let dotmsg = 0
			let para = {
				page: 1,
				pagesize: 50,
				type: 'sx',
				sp_status: 0,
			}
			return new Promise((resolve, reject) => {
				this.$http.post("/api/sx_sg_list", para).then(res => {
					if (res.data.data && res.data.data.length > 0) {
						res.data.data.map(a => {
							if (a.sp_status == 0 && a.icansp) {
								dotmsg = 1
							}
						})
						resolve(dotmsg)

					} else {
						resolve(dotmsg)
					}

				}).catch(err => {
					resolve(dotmsg)
				})
			})


		},
		getRKList() {
			let _this = this
			let dotmsg = 0
			let para = {
				page: 1,
				pagesize: 50,
				type: 'sx',
				sp_status: 0,
			}
			return new Promise((resolve, reject) => {
				this.$http.post("/api/get_teacher_info").then(userdata => {
					let userinfo = userdata.data
					this.$http.post("/api/sx_hc_rk_list", para).then(res => {
						if (res.data.data && res.data.data.length > 0) {
							res.data.data.map(a => {
								if (a.sp_status == 0 && a.store_admin == userinfo.id) {
									dotmsg = 1
								}
							})
							resolve(dotmsg)
						} else {
							resolve(dotmsg)
						}
					}).catch(err => {
						resolve(dotmsg)
					})
				})


			})


		},
		getSLList() {
			let _this = this
			let dotmsg = 0
			let para = {
				page: 1,
				pagesize: 50,
				type: 'sx',
				zc_type: "sx",
        sl_type: 1,
				sp_status: 0,
			}
			return new Promise((resolve, reject) => {
				this.$http.post("/api/zc_hc_sl_list", para).then(res => {
					if (res.data.data && res.data.data.length > 0) {
						res.data.data.map(a => {
							if (a.sp_status == 0 && a.icansp) {
								dotmsg = 1
							}
						})
						resolve(dotmsg)

					} else {
						resolve(dotmsg)
					}

				}).catch(err => {
					resolve(dotmsg)
				})
			})


		},
		getCKList() {
			let _this = this
			let dotmsg = 0
			let para = {
				page: 1,
				pagesize: 50,
				type: 'sx',
				zc_type: "sx",
				sp_status: 1,
				ck_status: '0'
			}
			return new Promise((resolve, reject) => {
				this.$http.post("/api/get_teacher_info").then(userdata => {
					let userinfo = userdata.data
					this.$http.post("/api/zc_hc_ck_list", para).then(res => {
						if (res.data.data && res.data.data.length > 0) {
							res.data.data.map(a => {
								if (a.sp_status == 1 && a.ck_status == 0 && a.store_admin == userinfo.id) {
									dotmsg = 1
								}
							})
							resolve(dotmsg)

						} else {
							resolve(dotmsg)
						}

					}).catch(err => {
						resolve(dotmsg)
					})
				})
			})
		},
	},
};
</script>
<style lang="less" type="text/less">
.menuWrapper {
	.el-tabs {
		height: 100%;

		.el-tabs__header {
			height: 100%;
			margin: 0;

			.el-tabs__nav-wrap {
				height: 100%;
				margin: 0;

				.el-tabs__nav-scroll {
					height: 100%;
					margin: 0;

					.el-tabs__active-bar {
						/*active   tabitem*/
						height: 0;
					}

					.el-tabs__nav {
						height: 100%;
						margin: 0;

						.el-tabs__item {
							height: 100%;

						}
					}
				}

				.el-tabs__nav-next,
				.el-tabs__nav-prev {
					margin-top: 5px;
					height: 40px;
					line-height: 40px;
					color: #ffcb29;
					background-color: #ffffff51;
				}
			}

			.el-tabs__nav-scroll::after {
				/*el-tab 下方长横线*/
				height: 0 !important;
			}

			.el-tabs__nav-wrap::after {
				/*el-tab 下方长横线*/
				height: 0 !important;
			}
		}
	}
}

.popper-class-cus {
	border-radius: 10px !important;
	box-shadow: 0 2px 12px 0 rgba(24, 144, 255, 0.5) !important;
}
</style>
<style scoped lang="less" type="text/less">
div {
	box-sizing: border-box;
}



.menuWrapper {

	.el-menu {

		.is-active,
		.is-active i {
			font-size: 18px;
			font-weight: 700;
		}
	}

	.header {
		height: 50px;
		width: 100%;
		position: absolute;
		top: 0;
		z-index: 1;
		background-color: #2469f3;

		.leftlogo {
			width: 200px;
			/*background-color: #fff;*/

			.thumbnail {
				width: calc(100% - 20px);
				height: 50px;
				overflow: hidden;

				img {
					max-width: 100%;
					/*max-height: 100%;
          */
				}
			}
		}

		.hidelogo {
			display: none;
		}

		.moudelname {
			margin-left: 20px;
			/*border-left: 4px solid #ffc501;*/
			color: #ffc501;
			padding-left: 12px;
			font-size: 18px;
			font-weight: bolder;
			white-space: nowrap;
		}

		.menubar {
			margin-left: 20px;
			height: 100%;
			overflow-y: hidden;

			.el-menu-item {
				border-bottom: 4px solid transparent !important;

			}

			.is-active {
				border-bottom: 4px solid #ffc501 !important;
			}

			ul {
				white-space: nowrap;
				height: 100%;

				li {
					height: 100%;
					line-height: 50px;
				}
			}

			img {
				width: 100%;
				margin-top: 0px;
			}

			.bmitem {
				height: 100%;
				/*padding: 3px 20px 0px 20px;*/
				border-radius: 4px;
				cursor: pointer;
				display: inline-block;
				vertical-align: top;
				font-weight: bolder;
				margin-right: 10px;
				font-size: 15px;
				color: #fff;

			}

			.bmckd {


				background-size: 100% 100%;
				font-size: 18px;
				color: #fff;
				transform: translateY(-2px);
				font-weight: bolder;

				.ckdline {
					height: 4px;
					background-color: #ffcb29;
					transform: translateY(-8px);
					border-radius: 2.5px;
				}
			}

		}

		.headright {
			margin: 0 20px 0 30px;
			height: 100%;
			cursor: pointer;

			.miniQRBox {
				margin-right: 20px;
				background-color: #fff;
				width: 36px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid #0794b3;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.nickname {
				margin-left: 10px;
				color: #fff;
				border-radius: 4px;
				cursor: pointer;
				border: 0;
				outline: none;
			}

			.avatarImg {
				width: 30px;
				height: 30px;
				border: 1px solid #0794b3;
				border-radius: 50%;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					cursor: pointer;

				}
			}
		}

	}

	.leftmenu {
		width: 200px;
		height: 100%;
		position: absolute;

		transition: all .3s;
		transform-origin: left;


		.menuitem {
			padding: 0 10px;
			height: 40px;
			line-height: 40px;
			text-align: left;

			cursor: pointer;
			margin: 0 auto;

			font-size: 14px;
			color: #879DF5;
			font-weight: bolder;
		}

		.menuckd {
			color: #3D62F6;
		}
	}

	.menuline {
		height: 2px;
		border: 2px solid #b9cbdf;
		position: absolute;
		top: 50px;
		width: 100%;
		background-color: #e1e9f9;
	}

	.hidemenu {
		transform: scale(0, 1);
	}

	.smenubox {
		width: 200px;
		padding-left: 10px;
		padding-top: 30px;
		margin: 0 auto;
		background-color: #fff;
		height: 95%;
		box-shadow: 2px 2px 6px 2px rgba(24, 144, 255, 0.1);

		.el-menu {
			border: 0;
		}

		.el-submenu__title {
			background-color: transparent !important;
		}
	}

	.hidemenu {
		transform: scale(0, 1);
	}

	.menuckd {

		color: #1890ff;
		box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		transform: translateX(10px);
		position: relative;
		background-color: #fafbfc;
	}

	.menuckd .ckdtail {
		width: 40px;
		height: 80px;
		position: absolute;
		top: -20px;
		right: -5px;
		background-image: url(//aiwx.org.cn/static/images/tail.png);
		background-size: auto 98%;

		background-repeat: no-repeat;
		background-color: #fafbfc;
	}
}
</style>

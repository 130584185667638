<template>
	<div class="page wrap">
		<sysmenu></sysmenu>

		<div v-if="$store.state.smenucount > 1" :class="['expandbtn', $store.state.leftmenu ? '' : 'hideexp']"
			@click="hideMenu">
			<div class="btnarrow">
				<!--        <i :class="$store.state.leftmenu?'el-icon-arrow-up':'el-icon-arrow-down'"></i>-->
				<img :class="$store.state.leftmenu ? 'trans90' : 'trans-90'" :src="'./img/expandBtn.png'"
					style="width: 20px;" />

			</div>
		</div>

		<div :class="['rightcontent', $store.state.leftmenu ? '' : 'expand']">
			<div :class="['rightpage', $store.state.leftmenu ? '' : 'expand2', isExpand3 ? 'expand3' : '']">
				<router-view />
			</div>
		</div>

	</div>
</template>

<script>
import sysmenu from './menu/menu.vue'

export default {
	components: {
		sysmenu
	},
	data() {
		return {
			showmenu: 1,
			mainbg: "",
			isExpand3: '',
		}
	},
	watch: {
		$route(to, from) {
			// console.log(36,to.path,from.path)
			if (to.path.indexOf('/home') >= 0) {
				this.isExpand3 = true
			} else {
				this.isExpand3 = false
			}
		},
		"$store.state.leftmenu": {
			handler: (n, o) => {
				// console.log(505050,n);
			}
		},
	},
	mounted() {

		this.getConfig();
		// this.$nextTick(()=>{
		// 	let smenubox = $(".smenubox").height()
		// 	$(".rightcontent").height(smenubox + 70)

		// 	$(window).resize(()=>{
		// 		smenubox = $(".smenubox").height()
		// 		$(".rightcontent").height(smenubox+70)

		// 	})
		// })
		if (window.location.href.indexOf('/home') >= 0) {
			this.isExpand3 = true
		}

		if (window.location.href.indexOf('showIframe') > -1) {
			let iframeUrl = decodeURIComponent(this.$GetQueryString('iframeUrl'))
			let nowbMenu = decodeURIComponent(this.$GetQueryString('nowbMenu'))
			let nowsMenu = decodeURIComponent(this.$GetQueryString('nowsMenu'))
			this.$store.dispatch("setShowIframe", 1)
			this.$store.dispatch("setIframeUrl", iframeUrl)
			this.$router.push({
				path: "/iframe",
				query: {
					iframeUrl: iframeUrl,
					t: Date.now(),
					nowbMenu: nowbMenu,
					nowsMenu: nowsMenu,
				}
			})
		} else {
			this.$store.dispatch("setShowIframe", 0)
		}


	},
	methods: {
		hideMenu() {
			if (this.$store.state.leftmenu) {
				this.$store.dispatch("showSmenu", 0)
			} else {
				this.$store.dispatch("showSmenu", 1)
			}

		},

		getConfig() {
			if (localStorage.getItem("config")) {
				let config = JSON.parse(localStorage.getItem("config"))
				this.$store.dispatch("setConfig", config)
				if (config.favico) {
					var link = document.createElement('link');
					link.type = 'image/x-icon';
					link.rel = 'shortcut icon';
					link.href = config.favico;
					document.getElementsByTagName('head')[0].appendChild(link);
				}
				if (config.favico_name) {
					document.title = `${config.favico_name}`
				}
			}
			this.$http.post("/api/sys_dictionary_list").then(res => {
				let config = {}
				if (res.data && res.data.length > 0) {
					res.data.map(a => {
						if (a.remark.indexOf('app基础配置') > -1 && a.key_values) {
							let json_Arr = JSON.parse(a.key_values)
							for (let item of json_Arr) {
								config[item.field] = item.value
							}
							if (config.favico) {
								var link = document.createElement('link');
								link.type = 'image/x-icon';
								link.rel = 'shortcut icon';
								link.href = config.favico;
								document.getElementsByTagName('head')[0].appendChild(link);
							}
							if (config.favico_name) {
								document.title = `${config.favico_name}`
							}
							this.$store.dispatch("setConfig", config)
							localStorage.setItem("config", JSON.stringify(config))
						}
					})
				}
			})
		},

	}
}
</script>
<style scoped lang="less">
.page {
	position: relative;
	overflow: hidden;
	background-size: cover;
}

/*.wrap {
	position: relative;
	height: 100vh;
	overflow: hidden;
	background-size: cover;
}*/

.rightcontent {
	margin-left: 222px;
	padding: 15px 30px 10px 10px;
	border-top: 50px solid transparent;
	width: calc(100% - 222px);
	height: 100%;
	transition: margin .3s;
	background-color: #fff;
}

.expand {
	margin-left: 0%;
	width: 100%;
}

.rightpage {
	background-color: #fafbfc;
	box-shadow: -2px -2px 6px 2px rgba(24, 144, 255, 0.1),
		2px 2px 6px 2px rgba(24, 144, 255, 0.1);
	border-radius: 8px;
	overflow: hidden;
	height: 100%;
	box-sizing: border-box;

	div {
		box-sizing: border-box;
	}

}

.expand2 {
	padding-left: 10px;
}

.expand3 {
	padding-left: 0 !important;
}

.expandbtn {

	width: 50px;
	height: 0;
	/*border-top: 20px solid rgba(118, 118, 118, 0.3);*/
	border-top: 20px solid transparent;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	position: absolute;
	top: 48%;
	left: 180px;
	color: #f6f6f6;
	transition: all 0.2s;
	transform-origin: top center;
	transform: rotateZ(-90deg) scaleY(1);
	cursor: pointer;
	z-index: 1999;
}

.trans-90 {
	transform: rotate(-90deg);
}

.trans90 {
	transform: rotate(90deg);
}

.hideexp {
	left: -20px;
}

.btnarrow {
	position: absolute;
	top: -30px;
	left: 15px;
	/*top: -20px;*/
	/*left: 16px;*/
}

.expandbtn:hover {
	/*border-top: 20px solid rgba(118, 118, 118, 0.6);*/
	border-top: 20px solid transparent;
	transform: rotateZ(-90deg) scaleY(1.1);
	color: #ccc;

}
</style>

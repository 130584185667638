<template>
  <div>
    <template v-for="menu in this.menuData">
      <el-submenu :key="menu.index" :index="menu.id.toString()" v-if="menu.children && menu.children.length > 0">
        <template slot="title">
          <div :class="['sub-menuitem', 'flexStart', nowsMenu == menu.id ? '' : '']">
            <i v-if="!menu.iconurl0 && !menu.iconurl1" :class="menu.icon"
              style="color: inherit;width: 15px;height: 15px;"></i>
            <img v-if="menu.iconurl1 && nowsMenu == menu.id" :src="menu.iconurl1" alt=""
              style="width: 15px;height: 15px;">
            <img v-if="menu.iconurl0 && nowsMenu != menu.id" :src="menu.iconurl0" alt=""
              style="width: 15px;height: 15px;">
            <span slot="title">{{ menu.title }}</span>
            <div class="ckdtail"></div>
          </div>
        </template>
        <menuTree :menuData="menu.children"></menuTree>
      </el-submenu>
      <el-menu-item :class="['menuitem', nowsMenu == menu.id ? 'menuckd' : '']" :index="menu.id.toString()" v-else>
        <div class="flexStart" @click="goPage(menu)">
          <i v-if="!menu.iconurl0 && !menu.iconurl1" :class="menu.icon"
            style="color: inherit;width: 15px;height: 15px;"></i>
          <img v-if="menu.iconurl1 && nowsMenu == menu.id" :src="menu.iconurl1" alt=""
            style="width: 15px;height: 15px;">
          <img v-if="menu.iconurl0 && nowsMenu != menu.id" :src="menu.iconurl0" alt=""
            style="width: 15px;height: 15px;">

          <div slot="title" style="position: relative;">{{ menu.title }}
            <span v-if="menu.dotmsg" style="position: absolute;width:6px;height:6px;background-color:#f56c6c;border-radius:50%;top:10px;"></span>
          </div>
        </div>
        <div class="ckdtail"></div>
      </el-menu-item>
    </template>
  </div>
</template>

<script>

export default {
  props: ['menuData'],
  name: 'MenuTree',
  data() {
    return {
      nowsMenu: "",
    };
  },
  watch: {
    '$store.state.nowsMenu'(n,o) {
      console.log(4848, n, o)
      this.nowsMenu = n

    }
  },
  mounted() { 
    this.nowsMenu = this.$store.state.nowsMenu
  },
  methods: {
    goPage(item) {
      if (this.$route.path != item.path) {
        this.$router.push(item.path)
        this.nowsMenu = item.id
      }
    },
  }
}
</script>

<style lang="less" scoped type="css/less">
.el-submenu .el-menu-item {
  min-width: auto;
}

.menuitem {
   padding: 0 10px;
   /*height: 40px !important;
   line-height: 40px !important;*/
   text-align: left;

   cursor: pointer;
   margin: 0 auto;

  font-size: 14px;
  color: #879DF5;
  font-weight: bolder;
}

.menuckd {
  color: #1890ff;
  /*box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transform: translateX(10px);*/
  position: relative;
  background-color: #d9ecff!important;

  /*.ckdtail {
    width: 40px;
    height: 80px;
    position: absolute;
    top: -20px;
    right: -5px;
    background-image: url(//aiwx.org.cn/static/images/tail.png);
    background-size: auto 98%;
    background-repeat: no-repeat;
    background-color: #fafbfc;
  }*/
}
</style>
<template>
  <div id="app">
    <router-view />

<!-- 
    <div id="log" @click="clearALl"
      style="position: fixed; bottom: 0; background-color: #fff; width: 100%;left: 0; z-index: 999999;"></div> -->

  </div>
</template>

<script>
export default {

  mounted() {
    // setInterval(() => {
    //   $("#log").text(window.location.href+"["+navigator.userAgent+"]")
 
    // }, 2000);

  }, methods: {
    clearALl() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      alert("清理完成")
    }
  }
}

</script>

<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#app {
  font-family: Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

.cbox {
  width: 100%;
  height: 100%;
  position: relative;
}

.bcontent {
  min-height: 65%;
  width: 100%;
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.headbar {
  padding: 10px;
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 10px;
  height: 55px;
  line-height: 40px;
  font-size: 14px;
}

.viewer-container {
  z-index: 99999 !important;
}
.workorder-remark {
  background-color: #303133 !important;
  color: #fff !important;
  border-color: #303133 !important;
  max-width: 30%;
  max-height: 300px;
  overflow-y: auto;
  line-height: 28px !important;
}

</style>

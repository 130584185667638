import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import Home from '../views/home/home'
import login from '../views/login'
Vue.use(VueRouter)


function isPhone() {

   

    var browser = {
        versions: function () {
            var u = navigator.userAgent, app = navigator.appVersion;
            return {
                //移动终端浏览器版本信息
                trident: u.indexOf('Trident') > -1, //IE内核
                presto: u.indexOf('Presto') > -1, //opera内核
                webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
                iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
                iPad: u.indexOf('iPad') > -1, //是否iPad
                webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
                miniApp: u.indexOf('micromessenger') > -1||u.indexOf('MicroMessenger') > -1 //是否web应该程序，没有头部与底部

            };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
    }

    if (browser.versions.android || browser.versions.iPhone || browser.versions.iPad || browser.versions.miniApp) {
        return true

    } else {
        return false
    }

}

let PcRouters = [
    {
        path: '*',
        redirect: "/home"
    },
    {
        path: '/login',
        component: login,
    },
    {
        path: '/home',
        component: Home,
    },
    {
        path: '/init',
        component: resolve => require(['../views/pages/init'], resolve)
    },
    {
        path: '/iframe',
        component: resolve => require(['../views/sys/sys_iframe.vue'], resolve)
    },
    { path: '/print_sg', component: resolve => require(['../h5/print/sg'], resolve), },
    { path: '/print_rk', component: resolve => require(['../h5/print/rk'], resolve), },
    { path: '/print_sl', component: resolve => require(['../h5/print/sl'], resolve), },
    {
        path: '/index',
        name: 'index',
        component: index,
        redirect: "/sx_purchase",
        children: [
            {
                path: '/sx_list',
                component: resolve => require(['../views/pages/sxhc/sxlist'], resolve)
            },
            {
                path: '/sx_purchase',
                component: resolve => require(['../views/pages/sxhc/sxpurchase'], resolve)
            },
            {
                path: '/sx_ruku',
                component: resolve => require(['../views/pages/sxhc/sxruku'], resolve)
            },
            {
                path: '/sx_shenling',
                component: resolve => require(['../views/pages/sxhc/sxshenling'], resolve)
            },
            {
                path: '/sx_chuku',
                component: resolve => require(['../views/pages/sxhc/sxchuku'], resolve)
            },
            {
                path: '/mysxhc',
                component: resolve => require(['../views/pages/sxhc/mysxhcList'], resolve)
            },
            {
                path: '/sx_distribute',
                component: resolve => require(['../views/pages/sxhc/sxdistribute'], resolve)
            },
            {
                path: '/sx_pandian',
                component: resolve => require(['../views/pages/sxhc/sxpandian'], resolve)
            },
            {
                path: '/sx_type',
                component: resolve => require(['../views/pages/sxhc/sxtype'], resolve)
            },
            {
                path: '/sx_stastic1',
                component: resolve => require(['../views/pages/sxhc/sx_stastic/sx_stastic1'], resolve)
            },
            {
                path: '/sx_stastic2',
                component: resolve => require(['../views/pages/sxhc/sx_stastic/sx_stastic2'], resolve)
            },
            {
                path: '/sx_stastic3',
                component: resolve => require(['../views/pages/sxhc/sx_stastic/sx_stastic3'], resolve)
            },


            {
                path: '/home',
                component: resolve => require(['../views/home/home'], resolve)
            },
            {
                path: '/list',
                component: resolve => require(['../views/pages/list'], resolve)
            },
            {
                path: '/process_sg',
                component: resolve => require(['../views/pages/process'], resolve)
            },
            {
                path: '/process_sl',
                component: resolve => require(['../views/pages/process_sl'], resolve)
            },
            {
                path: '/process_tssl',
                component: resolve => require(['../views/pages/process_tssl'], resolve)
            },
            {
                path: '/sys_menu',
                component: resolve => require(['../views/sys/sys_menu'], resolve)
            },
            {
                path: '/sys_term',
                component: resolve => require(['../views/sys/sys_term'], resolve)
            },
            {
                path: '/sys_menu_app',
                component: resolve => require(['../views/sys/sys_menu_app'], resolve)
            },
            {
                path: '/sys_role',
                component: resolve => require(['../views/sys/sys_role'], resolve)
            },
            {
                path: '/tch_role',
                component: resolve => require(['../views/sys/tch_role'], resolve)
            },
            {
                path: '/process_borrow',
                component: resolve => require(['../views/pages/sys/process_borrow.vue'], resolve)
            },
            {
                path: '/process_ly',
                component: resolve => require(['../views/pages/sys/process_ly.vue'], resolve)
            },
            {
                path: '/hcprocess',
                component: resolve => require(['../views/pages/sys/hcprocess.vue'], resolve)
            },
            {
                path: '/sys_dictionary',
                component: resolve => require(['../views/sys/sys_dictionary.vue'], resolve)
            },

            //固定资产
            {
                path: '/test',
                component: resolve => require(['../views/pages/zc/info.vue'], resolve)
            },
            {
                path: '/zclist',
                component: resolve => require(['../views/pages/zc/zclist.vue'], resolve)
            },
            {
                path: '/zcstore',
                component: resolve => require(['../views/pages/zc/zcstore.vue'], resolve)
            },
            {
                path: '/zctype',
                component: resolve => require(['../views/pages/zc/zctype.vue'], resolve)
            },
            {
                path: '/hctype',
                component: resolve => require(['../views/pages/hc/hctype.vue'], resolve)
            }, {
                path: '/dztype',
                component: resolve => require(['../views/pages/nyp/dztype.vue'], resolve)
            }, {
                path: '/pandian',
                component: resolve => require(['../views/pages/zc/pandian.vue'], resolve)
            },
            {
                path: '/borrow',
                component: resolve => require(['../views/pages/zc/brw_rtn.vue'], resolve)
            },
            {
                path: '/zclingyong',
                component: resolve => require(['../views/pages/zc/lingyong.vue'], resolve)
            },
            {
                path: '/distribute',
                component: resolve => require(['../views/pages/zc/dstr_wthdrw.vue'], resolve)
            },
            // 耐用品
            {
                path: '/nyplist',
                component: resolve => require(['../views/pages/nyp/nyplist.vue'], resolve)
            },
            {
                path: '/nyptype',
                component: resolve => require(['../views/pages/nyp/nyptype.vue'], resolve)
            },
            // 耗材
            {
                path: '/hclist',
                component: resolve => require(['../views/pages/hc/hclist.vue'], resolve)
            },
            {
                path: '/PfOrSlList',
                component: resolve => require(['../views/pages/hc/PfOrSlList.vue'], resolve)
            },
            {
                path: '/hctype',
                component: resolve => require(['../views/pages/hc/hctype.vue'], resolve)
            },
            {
                path: '/teacher',
                component: resolve => require(['../views/sys/teacher.vue'], resolve)
            }, {
                path: '/department',
                component: resolve => require(['../views/sys/department.vue'], resolve)
            }, {
                path: '/deptrole',
                component: resolve => require(['../views/sys/deptRole.vue'], resolve)
            },{
                path: '/sys_module',
                component: resolve => require(['../views/sys/sys_module.vue'], resolve)
            },{
                path: '/sys_module_roles',
                component: resolve => require(['../views/sys/sys_module_roles.vue'], resolve)
            },{
                path: '/syskc',
                component: resolve => require(['../views/pages/shiyanshi/syskc.vue'], resolve)
            },{
                path: '/syssy',
                component: resolve => require(['../views/pages/shiyanshi/syssy.vue'], resolve)
            }, {
                path: '/syssyRegister',
                component: resolve => require(['../views/pages/shiyanshi/syssy_register.vue'], resolve)
            }, {
                path: '/sysguanli',
                component: resolve => require(['../views/pages/shiyanshi/sysguanli.vue'], resolve)
            }, {
                path: '/sysDetail',
                component: resolve => require(['../views/pages/shiyanshi/sysguanliDetail.vue'], resolve)
            }, {
                path: '/sysjiancha',
                component: resolve => require(['../views/pages/shiyanshi/sysjiancha.vue'], resolve)
            },

            {
                path: '/sysyinhuan',
                component: resolve => require(['../views/pages/shiyanshi/sysyinhuan.vue'], resolve)
            },
            {
                path: '/sysjcjh',
                component: resolve => require(['../views/pages/shiyanshi/sysjcjh.vue'], resolve)
            },

            {
                path: '/sysbaojing',
                component: resolve => require(['../views/pages/shiyanshi/sysbaojing.vue'], resolve)
            },
            {
                path: '/sysdownload',
                component: resolve => require(['../views/pages/shiyanshi/sysdownload.vue'], resolve)
            },
            {
                path: '/syslog',
                component: resolve => require(['../views/pages/sys/syslog.vue'], resolve)
            },
            {
                path: '/classes',
                component: resolve => require(['../views/sys/sys_classes.vue'], resolve)
            },
            {
                path: '/syyxb',
                component: resolve => require(['../views/pages/shiyanshi/syyxb.vue'], resolve)
            },
            //实验室使用记录
            {
                path:'/syl',
                component: resolve => require(['../views/pages/shiyanshi/syssylshwx/syl.vue'], resolve)
            },
            {
                path:'/sbsh',
                component: resolve => require(['../views/pages/shiyanshi/syssylshwx/sbsh.vue'], resolve)
            },
            {
                path:'/hcyqjcg',
                component: resolve => require(['../views/pages/shiyanshi/syssylshwx/hcyqjcg.vue'], resolve)
            },
            {
                path:'/cgjd',
                component: resolve => require(['../views/pages/shiyanshi/syssylshwx/cgjd.vue'], resolve)
            },
            {
                path:'/bywx',
                component: resolve => require(['../views/pages/shiyanshi/syssylshwx/bywx.vue'], resolve)
            },
            {
                path:'/rjcb',
                component: resolve => require(['../views/pages/shiyanshi/wsjc/rjcb.vue'], resolve)
            },
            {
                path:'/zjcb',
                component: resolve => require(['../views/pages/shiyanshi/wsjc/zjcb.vue'], resolve)
            }
            ,
            {
                path:'/syskcl',
                component: resolve => require(['../views/pages/shiyanshi/syskcl.vue'], resolve)
            },
            {
                path:'/special_sl',
                component: resolve => require(['../views/pages/sxhc/special_sl.vue'], resolve)
            }

        ]
    }
]

let H5Routers = [
    {
        path: '/nodata',
        component: resolve => require(['../h5/nodata.vue'], resolve)
    },
    {
        path: '/data',
        component: resolve => require(['../h5/datacenter/datahome.vue'], resolve)
    },
]

let routes = [];
if (isPhone()) {
    routes = H5Routers
} else {
    routes = PcRouters
}

// console.log(routes)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function (name) {
    var params = [];
    var value = null;
    if (window.location.href.indexOf(name + "=") >= 0) {
        let q = window.location.href.split("?")[1];
        if (q.indexOf("#") > 0) {
            q = q.split("#")[0];
        }
        params = q.split("&");
        for (let p of params) {
            if (p.indexOf(name + "=") >= 0) {
                value = p.replace(name + "=", "").replace(/\//g, "")
                break;
            }
        }
    }
    if (value && value != undefined && value != "undefined") {
        return value
    } else {
        return "";
    }
}



//路由进入之前检查是否登录
router.beforeEach((to, from, next) => {
    console.log('to.path', to.path,)
    let WhiteList = ['/login','/print_sg', '/print_rk', '/print_sl', '/nodata']
    if (WhiteList.indexOf(to.path) > -1) {
        //白名单不验证登录
        next()
    } else {
        let auth = $request("auth") || $request("token");
        if (auth) {
            if (isPhone()) {//手机端，直接跳转
                if($request("ipx")){
                    window.localStorage.setItem("ipx",1)
                }
                window.localStorage.setItem("token", auth); //将登录字符串保存到缓存
                next()
            } else {
                window.localStorage.setItem("auth", auth); //将登录字符串保存到缓存
                //移除url的auth并刷新页面
                let localUrl = window.location.href.replace(auth, '').replace("?auth=", '').replace("&auth=", '')
                // alert(localUrl)
                // alert(auth)
                window.location.href = localUrl

                setTimeout(() => {
                     window.location.reload()
                }, 100);
            }
        } else { //跳转统一登录

            auth = window.localStorage.getItem("auth")||window.localStorage.getItem("token")
            if (auth) {
                next()
            } else {
                if (isPhone()) {//手机端，直接跳转
                    if($request("ipx")){
                        window.localStorage.setItem("ipx",1)
                    }
                    next("/nodata")
                } else {
                    next("/login")
                }

            }
        }
    }
})


export default router

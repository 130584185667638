<template>
  <div style="height: 100%;">
    <homePage></homePage>
  </div>
</template>

<script>
export default {
  components:{
    homePage: () => {
      if (window.location.href.indexOf("ngzzc.") >= 0) {
        return import("./ngz/home_ngz.vue")
      } else {
        return import("./ngz/home_ngz.vue")
      }
    }
  },
  // if (window.pmid == 101) {
  //   return import("./ngz/home_ngz.vue")
  // }
  data() {
    return {
      schoolName:""
    }
  },
  mounted() {
    if (window.location.href.indexOf("zhzz") >= 0) {this.schoolName = "zhzz"}
    else if (window.location.href.indexOf("zichan") >= 0) {this.schoolName = "ngz"}

    else{
      this.schoolName="common"
    }


  },
  methods: {


  }
}

</script>

<style>
</style>
